<template>
  <div class="speech_container" v-if="role === 'therapist'">
    <b-row>
      <b-col cols="8">
        <b-container fluid>
          <!-- Seçili sorunun bilgilerini göster -->
          <b-row>
            <b-col cols="12" class="mb-3">
              <b-card no-body>
                <b-card-text class="font-readable-bold display-10"><strong>Yönerge: </strong>{{
                  remoteSelectedQuestion.instruction }}</b-card-text>
                <!-- Burada 'font-weight-bold' sınıfı metni kalın yapar ve 
           'display-4' sınıfı metni büyütür. -->
                <!-- <b-card-text class="font-readable-bold display-5">{{ remoteSelectedQuestion.question }}</b-card-text> -->
                <b-card-text class="font-readable-bold display-5" v-html="remoteSelectedQuestion.question"></b-card-text>
              </b-card>
            </b-col>
          </b-row>

          <!-- Seçili sorunun resimlerini göster ve seçilebilir yap -->
          <b-row>
            <b-col cols="4" v-for="(image, imageIndex) in remoteSelectedQuestion.images" :key="imageIndex" class="mb-3">
              <b-card no-body @click="remoteSelectImage(imageIndex)"
                :class="{ 'border-primary': remoteIsSelectedImage(imageIndex) }">
                <!-- 'img-height' sınıfı ekleyerek yüksekliği ayarlayın -->
                <b-card-img :src="image" alt="Resim" class="img-height" thumbnail fluid></b-card-img>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-col>

      <b-col cols="4">
        <div>Konuşma Akıcılığı Değerlendirme Puanı: <strong>{{ fluentPoint }}</strong></div>
        <div>İşitsel Anlama Değerlendirme Puanı: <strong>{{ auditoryPoint }}</strong></div>
        <div>Tekrarlamayı Değerlendirme Puanı: <strong>{{ repeatPoint }}</strong></div>
        <div>Adlandırma Değerlendirme Puanı: <strong>{{ namingPoint }}</strong></div>
        <div>Okuma Değerlendirme Puanı: <strong>{{ readingPoint }}</strong></div>
        <div>Dilbilgisi Değerlendirme Puanı: <strong>{{ grammarPoint }}</strong></div>
        <div>Söz Eylemleri Değerlendirme Puanı: <strong>{{ actsPoint }}</strong></div>
        <div>Yazma Değerlendirme Puanı: <strong>{{ writePoint }}</strong></div>
        <div>Toplam Puan: <strong>{{ totalPoint }}</strong></div>
      </b-col>
    </b-row>
    <b-row>
      <!-- Yanıt Durumu Buton Grubu -->
      <b-col cols="6">
        <div class="w-100">
          <div>Yanıt Durumu: <strong>{{ selectedAnswerStatus }}</strong></div>
          <b-button-group>
            <b-button variant="success" @click="selectAnswerStatus('Doğru')" block>Doğru</b-button>
            <b-button variant="warning" @click="selectAnswerStatus('Eksik')" block>Eksik</b-button>
            <b-button variant="danger" @click="selectAnswerStatus('Yanlış')" block>Yanlış</b-button>
            <b-button variant="secondary" @click="selectAnswerStatus('Yanıt Yok')" block>Yanıt Yok</b-button>
          </b-button-group>
        </div>
      </b-col>

      <!-- Yanıt Şekli Buton Grubu -->
      <b-col cols="6">
        <div class="w-100">
          <div>Yanıt Şekli: <strong>{{ selectedAnswerType }}</strong></div>
          <b-button-group>
            <b-button variant="success" @click="selectAnswerType('Konuşma')" block>Konuşma</b-button>
            <b-button variant="secondary" @click="selectAnswerType('Yazma')" block>Yazma</b-button>
            <b-button variant="success" @click="selectAnswerType('Jest')" block>Jest</b-button>
            <b-button variant="secondary" @click="selectAnswerType('İşaretleme')" block>İşaretleme</b-button>
          </b-button-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <!-- Yanıt için Metin Girişi -->
      <b-col cols="12" class="my-3">
        <label for="response-input">Yanıt:</label>
        <b-form-input id="response-input" v-model="selectedAnswerText" placeholder="Yanıtınızı girin"></b-form-input>
      </b-col>
      <!-- Hatalı Üretim Şekli için Metin Girişi -->
      <b-col cols="12" class="my-3">
        <label for="error-production-input">Hatalı Üretim Şekli:</label>
        <b-form-input id="error-production-input" v-model="selectedFault"
          placeholder="Hatalı üretim şeklini girin"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <!-- Ekleme Butonu -->
        <div class="w-100">
          <b-button variant="primary" @click="addButtonClicked" block
            v-show="!isAdded && selectedAnswerStatus && selectedAnswerType">Ekle</b-button>
        </div>
      </b-col>
      <b-col cols="1">
        <b-icon icon="arrow-right-circle" font-scale="3" @click="nextButtonClicked" variant="success"
          v-if="isAdded && !isLastQuestion"></b-icon>
        <!-- <b-icon icon="arrow-right-circle" font-scale="3" @click="nextButtonClicked" variant="success"></b-icon> -->
      </b-col>
      <b-col cols="5">
        <!-- Ekleme Butonu -->
        <div class="w-100">
          <b-button variant="primary" v-if="isAdded && isLastQuestion" @click="addToReport" block>Rapor Olarak
            Ekle</b-button>
        </div>

        <br></br>
      </b-col>
    </b-row>
    <b-row>
      <br></br>
      <div>Soru No: <strong>{{ remoteCurrentQuestionNo }}</strong></div>
      <div>Sorularda hızlı gezinmek için aşağıdaki butonları kullanabilirsiniz. (Test 145 sorudan oluşmaktadır.)</div>
      <br></br>
      <b-col cols="1">
        <b-icon icon="arrow-left-circle" font-scale="3" @click="beforeButtonClicked" variant="success"></b-icon>
      </b-col>
      <b-col cols="1">
        <b-icon icon="arrow-right-circle" font-scale="3" @click="nextButtonClicked" variant="success"></b-icon>
      </b-col>
    </b-row>
  </div>
  <div class="speech_container" v-else>
    <b-container fluid>
      <!-- Seçili sorunun bilgilerini göster -->
      <!-- <b-row v-if="['reading', 'grammar', 'acts', 'write'].includes(selectedQuestion.question_type)">
        <b-col cols="12" class="mb-3">
          <b-card no-body>
            <b-card-text class="font-readable-bold display-5">{{ selectedQuestion.question }}</b-card-text>
          </b-card>
        </b-col>
      </b-row> -->
      <b-row v-if="['reading', 'grammar', 'acts', 'see'].includes(selectedQuestion.question_type)">
        <b-col cols="12" class="mb-3">
          <b-card no-body>
            <b-card-text class="font-readable-bold display-5" v-html="selectedQuestion.question"></b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <!-- Seçili sorunun resimlerini göster ve seçilebilir yap -->
      <b-row>
        <b-col cols="4" md="3" v-for="(image, imageIndex) in selectedQuestion.images" :key="imageIndex" class="mb-3">
          <b-card no-body @click="selectImage(imageIndex)" :class="{ 'border-primary': isSelectedImage(imageIndex) }">
            <!-- 'img-height' sınıfı ekleyerek yüksekliği ayarlayın -->
            <b-card-img :src="image" alt="Resim" class="img-height-patient" thumbnail fluid></b-card-img>
          </b-card>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import mqtt from "mqtt";
import questions from '@/utils/data/questions.json';
import AphasiaAnswerService from "../service/AphasiaAnswerService";
import AphasiaPointService from "../service/AphasiaPointService";
export default {
  data() {
    return {
      role: null,
      appointmentId: null,
      testDatas: [],

      selectedAnswerStatus: '', // Seçilen Yanıt Durumu
      selectedAnswerType: '',   // Seçilen Yanıt Şekli
      selectedAnswerText: '',  // Seçilen Yanıt
      selectedFault: '', // Seçilen Hatalı Üretim Şekli

      //Konuşma akıcılığı değerlendirme puanı
      fluentPoint: 0,
      //İşitsel anlama değerlendirme puanı
      auditoryPoint: 0,
      //Tekrarlamayı değerdirme puanı
      repeatPoint: 0,
      //Adlandırma değerdirme puanı
      namingPoint: 0,
      //Okuma değerdirme puanı
      readingPoint: 0,
      //Dilbilgisi değerdirme puanı
      grammarPoint: 0,
      //Söz eylemleri değerdirme puanı
      actsPoint: 0,
      //Yazma değerdirme puanı
      writePoint: 0,
      //Toplam puan
      totalPoint: 0,

      // selectedQuestion: {},
      selectedImageIndex: null,
      currentQuestionNo: 1, // Varsayılan olarak gösterilecek soru numarası

      remoteSelectedImageIndex: null,
      remoteCurrentQuestionNo: 1,
      // remoteSelectedQuestion: {},
      isAdded: false,

      mqttComingJson: null,

      aphasiaAnswer: {},
      aphasiaPoint: {},

      aphasiaAnswerService: new AphasiaAnswerService(),
      aphasiaPointService: new AphasiaPointService(),
    };
  },
  computed: {
    selectedQuestion() {
      // Seçili soru numarasına göre soruyu bul
      return this.testDatas.find(question => question.question_no === this.currentQuestionNo) || {};
    },
    remoteSelectedQuestion() {
      // Seçili soru numarasına göre soruyu bul
      return this.testDatas.find(question => question.question_no === this.remoteCurrentQuestionNo) || {};
    },
    isLastQuestion() {
      return this.remoteCurrentQuestionNo === this.testDatas.length;
    },
  },
  methods: {
    selectImage(imageIndex) {
      // Seçili resim indexini güncelle
      this.selectedImageIndex = imageIndex;
      this.patientSendSelect();
    },
    isSelectedImage(imageIndex) {
      // Seçili resim olup olmadığını kontrol et
      return this.selectedImageIndex === imageIndex;
    },
    remoteSelectImage(imageIndex) {
      // Seçili resim indexini güncelle
      this.remoteSelectedImageIndex = imageIndex;
      this.therapistSendSelect();
    },
    remoteIsSelectedImage(imageIndex) {
      // Seçili resim olup olmadığını kontrol et
      return this.remoteSelectedImageIndex === imageIndex;
    },
    selectAnswerStatus(status) {
      this.selectedAnswerStatus = status;
    },
    selectAnswerType(type) {
      this.selectedAnswerType = type;
    },
    addButtonClicked() {
      // Sorunun tipine göre puanlama
      switch (this.remoteSelectedQuestion.question_type) {
        case 'fluent':
          this.updatePoints('fluentPoint');
          break;
        case 'auditory':
          this.updatePoints('auditoryPoint');
          break;
        case 'repeat':
          this.updatePoints('repeatPoint');
          break;
        case 'naming':
          this.updatePoints('namingPoint');
          break;
        case 'reading':
          this.updatePoints('readingPoint');
          break;
        case 'grammar':
          this.updatePoints('grammarPoint');
          break;
        case 'acts':
          this.updatePoints('actsPoint');
          break;
        case 'write':
          this.updatePoints('writePoint');
          break;
        case 'see':
          this.updatePoints('writePoint');
          break;
      }
      this.aphasiaAnswer.answer = this.selectedAnswerText;
      this.aphasiaAnswer.faulty_word = this.selectedFault;
      this.aphasiaAnswer.answer_type = this.selectedAnswerType;
      this.aphasiaAnswer.appointment_id = this.appointmentId;
      this.aphasiaAnswerService.createAphasiaAnswer(this.aphasiaAnswer).then((response) => {
        console.log(response);
        console.log(response.status);
        // alert("Cevap oluşturma başarılı.");
      }).catch((error) => {
        console.log("Error creating createAphasiaAnswer", error);
      });
      this.isAdded = true;
    },
    nextButtonClicked() {
      this.selectedAnswerStatus = ''; // Seçilen Yanıt Durumu
      this.selectedAnswerType = '';   // Seçilen Yanıt Şekli
      this.selectedAnswerText = '';  // Seçilen Yanıt
      this.selectedFault = ''; // Seçilen Hatalı Üretim Şekli
      this.remoteSelectedImageIndex = null;
      this.isAdded = false;
      this.remoteCurrentQuestionNo += 1;
      this.therapistSendNext();
    },
    beforeButtonClicked() {
      this.selectedAnswerStatus = ''; // Seçilen Yanıt Durumu
      this.selectedAnswerType = '';   // Seçilen Yanıt Şekli
      this.selectedAnswerText = '';  // Seçilen Yanıt
      this.selectedFault = ''; // Seçilen Hatalı Üretim Şekli
      this.remoteSelectedImageIndex = null;
      this.isAdded = false;
      this.remoteCurrentQuestionNo -= 1;
      this.therapistSendNext();
    },
    // Puanlama işlemini gerçekleştiren yardımcı metot
    updatePoints(pointType) {
      this.aphasiaAnswer.question = this.remoteSelectedQuestion.question;
      this.aphasiaAnswer.answer_accuracy = this.selectedAnswerStatus;
      console.log(pointType);
      if (this.selectedAnswerStatus === 'Doğru') {
        this[pointType] += 2;
        this.totalPoint += 2;
        this.aphasiaAnswer.points_earned = 2;
      } else if (this.selectedAnswerStatus === 'Eksik') {
        this[pointType] += 1;
        this.totalPoint += 1;
        this.aphasiaAnswer.points_earned = 1;
      } else {
        this.aphasiaAnswer.points_earned = 0;
      }
    },
    therapistSendNext() {
      this.doPublish(
        `{ "role": "${this.role}","question": "${this.remoteCurrentQuestionNo}","method":"next"}`
      );
    },
    therapistSendSelect() {
      this.doPublish(
        `{ "role": "${this.role}","index":"${this.remoteSelectedImageIndex}","method":"select"}`
      );
    },
    patientSendSelect() {
      this.doPublish(
        `{ "role": "${this.role}","index":"${this.selectedImageIndex}","method":"select"}`
      );
    },
    therapistComingSelectMessage() {
      this.remoteSelectedImageIndex = this.mqttComingJson.index;
    },
    patientComingSelectMessage() {
      this.selectedImageIndex = this.mqttComingJson.index;
    },
    patientComingNextMessage() {
      this.selectedImageIndex = null;
      this.currentQuestionNo = parseInt(this.mqttComingJson.question);
    },
    createConnection(gameName, appointmentId) {
      const connectUrl = `wss://konusmaterapim.com:8084/mqtt`;
      const clientId = uuidv4();
      this.mqttTopic = gameName + "/" + appointmentId;
      try {
        const mqttClient = mqtt.connect(connectUrl, {
          clientId: clientId,
          username: "username", // burası gerçek kullanıcı adınızla değiştirilmeli
        });
        mqttClient.on("connect", () => {
          console.log("Connection succeeded!");
        });
        mqttClient.on("error", (error) => {
          console.log("Connection failed", error);
          mqttClient.end();
        });
        mqttClient.on("message", (topic, message) => {
          this.mqttMessageController(message);
        });
        this.client = mqttClient;
        this.doSubscribe(mqttClient, gameName + "/" + appointmentId);
      } catch (error) {
        console.log("mqtt.connect error", error);
      }
    },
    mqttMessageController(message) {
      // setMqttMessageCome(JSON.parse(message));
      // console.log(JSON.parse(message));
      this.mqttComingJson = JSON.parse(message);
      if (this.mqttComingJson.role !== this.role && this.role != null) {
        console.log(JSON.parse(message));
        if (this.mqttComingJson.method === "select") {
          if (this.role === "therapist") {
            this.therapistComingSelectMessage();
          } else if (this.role === "patient") {
            this.patientComingSelectMessage();
          }
        } else if (this.mqttComingJson.method === "next") {
          if (this.role === "patient") {
            this.patientComingNextMessage();
          }
        }
      }
    },
    doSubscribe(client, mqttTopic) {
      console.log("doSubscribe");
      client.subscribe(mqttTopic, { qos: 0 }, (error, res) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        console.log("Subscribe to topics res", res);
      });
    },
    doPublish(message) {
      if (!this.role) {
        return;
      }
      this.client.publish(this.mqttTopic, message, { qos: 0 }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    },
    addToReport() {
      if (this.role === "therapist") {
        this.aphasiaPoint.fluent_point = this.fluentPoint;
        this.aphasiaPoint.auditory_point = this.auditoryPoint;
        this.aphasiaPoint.repeat_point = this.repeatPoint;
        this.aphasiaPoint.naming_point = this.namingPoint;
        this.aphasiaPoint.reading_point = this.readingPoint;
        this.aphasiaPoint.grammar_point = this.grammarPoint;
        this.aphasiaPoint.acts_point = this.actsPoint;
        this.aphasiaPoint.write_point = this.writePoint;
        this.aphasiaPoint.total_point = this.totalPoint;
        this.aphasiaPoint.appointment_id = this.appointmentId;

        this.aphasiaPointService.createAphasiaPoint(this.aphasiaPoint).then((response) => {
          console.log(response);
          console.log(response.status);
          alert("Rapor oluşturma başarılı.");
        }).catch((error) => {
          console.log("Error creating createAphasiaPoint", error);
        });
      }
    },
  },

  created() {
    this.testDatas = questions; // Assuming the data is static and does not need to be fetched asynchronously
    console.log(this.testDatas);
  },
  mounted() {
    localStorage.removeItem('role'); // Optionally remove the token from storage
    localStorage.removeItem('appointmentId'); // Optionally remove the token from storage

    let url = new URL(window.location.href);
    this.role = url.searchParams.get("role");
    this.appointmentId = url.searchParams.get("appointmentId");

    localStorage.setItem('role', this.role); // Optionally remove the token from storage
    localStorage.setItem('appointmentId', this.appointmentId); // Optionally remove the token from storage
    console.log(this.story_no);
    if (this.role) {
      this.createConnection("afazi", this.appointmentId);
    }
  },
}
</script>

<style>
.border-primary {
  border: 10px solid blue !important;
  /* Açık mavi tonu */
}

.font-readable {
  font-family: 'Roboto', sans-serif;
  /* Burada Roboto fontunu uyguluyoruz */
  /* font-size: 1.25rem;
  /* Font boyutunu ayarlayabilirsiniz */
  font-weight: 400;
  /* Normal font kalınlığı için */
}

.font-readable-bold {
  font-family: 'Roboto', sans-serif;
  /* Kalın versiyon için aynı font ailesi */
  /* font-size: 1.25rem;
  /* Font boyutunu ayarlayabilirsiniz */
  font-weight: 700;
  /* Daha kalın font için */
}

/* Öğelerin yüksekliğini ayarlayın */
.img-height {
  max-height: calc(100vh / 3);
  /* Ekranın yüksekliğinin altıda biri kadar */
  object-fit: stretch;
  /* Resmin kutuya sığmasını sağlar */
}

/* Öğelerin yüksekliğini ayarlayın */
.img-height-patient {
  max-height: calc(90vh / 4);
  /* Ekranın yüksekliğinin altıda biri kadar */
  object-fit: stretch;
  /* Resmin kutuya sığmasını sağlar */
}
</style>