import http from "../utils/ApiReportLocal"; // Update the path accordingly
export default class AphasiaAnswerService {
  createAphasiaAnswer = async (measurement) => {
    try {
      const response = await http.post("api/aphasiaanswer/create", measurement);
      // Handle success
      return response;
    } catch (error) {
      // Handle error
    }
  };
}
