// import axios from "axios";

// const http = axios.create({
//   baseURL: "http://localhost:8080/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// export default http;
import axios from "axios";

const http = axios.create({
  baseURL: "https://report.dilvekonusmaterapim.com/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default http;
