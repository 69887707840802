<template>
  <div id="app">
    <app-header></app-header>
    <div id="appContainer">
      <workScreen></workScreen>
    </div>
  </div>
</template>

<script>
import appHeader from "@/components/appHeader";
import workScreen from "@/components/workScreen";

export default {
  name: 'App',
  components: {
    appHeader,
    workScreen
  }
}
</script>
